import * as backendService from "../backendService";
import { fromCamelToSnake, fromSnakeToCamel } from "../../utils/letterCasingUtils";

export const list = async ({ organizationId }) => {
  const url = `/organizations/${organizationId}/item-categories`;
  const response = await backendService.get({ url });

  return { data: fromSnakeToCamel(response.data) };
};

export const create = async ({ organizationId, name, transactionType }) => {
  const url = `/organizations/${organizationId}/item-categories`;
  const response = await backendService.post({ url, data: fromCamelToSnake({ name, transactionType }) });

  return { data: fromSnakeToCamel(response.data) };
};

export const update = async ({ organizationId, id, name }) => {
  const url = `/organizations/${organizationId}/item-categories/${id}`;
  const response = await backendService.patch({ url, data: fromCamelToSnake({ name }) });

  return { data: fromSnakeToCamel(response.data) };
};

export const destroy = async ({ organizationId, id }) => {
  const url = `/organizations/${organizationId}/item-categories/${id}`;
  const response = await backendService.destroy({ url });

  return { data: fromSnakeToCamel(response.data) };
};
