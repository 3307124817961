import { combineReducers } from "redux";

import { reducer as appConfigurationReducer } from "./services/app-configurations/reducers";
import { reducer as loginReducer } from "./services/login/reducers";
import { reducer as documentsReducer } from "./services/documents/reducers";
import { reducer as documentCategoryReducer } from "./services/document-categories/reducers";
import { reducer as documentExportReducer } from "./services/document-exports/reducers";
import { reducer as documentMassUploadReducer } from "./services/document-mass-uploads/reducers";
import { reducer as itemCategoryReducer } from "./services/item-categories/reducers";
import { reducer as taxRateReducer } from "./services/tax-rates/reducers";
import { reducer as currencyReducer } from "./services/currency/reducers";
import { reducer as localeReducers } from "./services/locales/reducers";
import { reducer as organizationReducer } from "./services/organization/reducers";
import { reducer as hashTagReducer } from "./services/hash-tags/reducers";
import { reducer as paymentMethodReducer } from "./services/payment-methods/reducers";
import { reducer as paymentMethodAccountReducer } from "./services/payment-method-accounts/reducers";
import { reducer as organizationWebhookReducer } from "./services/organization-webhooks/reducers";
import { reducer as transactionLineCollectionReducer } from "./services/transaction-line-collections/reducers";
import { reducer as bankStatementReducer } from "./services/bank-statements/reducers";
import { reducer as accountablesReducer } from "./services/accountables/reducers";
import { reducer as accountingIntegrationReducer } from "./services/integrations/accounting/reducers";

export const configureReducers = (additionalReducers = {}) =>
  combineReducers({
    applicationConfiguration: appConfigurationReducer,
    login: loginReducer,
    documents: documentsReducer,
    documentCategories: documentCategoryReducer,
    documentExports: documentExportReducer,
    documentMassUploadTasks: documentMassUploadReducer,
    itemCategories: itemCategoryReducer,
    accountables: accountablesReducer,
    organization: organizationReducer,
    currency: currencyReducer,
    locales: localeReducers,
    taxRates: taxRateReducer,
    hashTags: hashTagReducer,
    paymentMethods: paymentMethodReducer,
    paymentMethodAccounts: paymentMethodAccountReducer,
    organizationWebhooks: organizationWebhookReducer,
    transactionLineCollections: transactionLineCollectionReducer,
    bankStatements: bankStatementReducer,
    accountingIntegration: accountingIntegrationReducer,
    ...additionalReducers,
  });
