import { call, delay, put, take } from "redux-saga/effects";
import * as api from "./api";
import { accountingIntegrationActions } from "./actions";
import { debugPrintAndLogError } from "../../logging/logger";
import { documentActions } from "../../documents/actions";
import { useCurrentOrganizationId } from "../../../utils/sagaUtils";
import { itemCategoryActions } from "../../item-categories/actions";

export const accountingIntegrationSagas = {
  *updateSettings(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        platform,
        defaultPaymentMethodAccountId,
      } = action.payload;

      const response = yield call(api.updateAccountingIntegrationSettings, {
        organizationId,
        platform,
        defaultPaymentMethodAccountId,
      });

      yield put(accountingIntegrationActions.updateSettings.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.updateSettings.error(error));
    }
  },
  *syncAccountingIntegrationData(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        platform,
        onCompletion = () => {},
        onFailed = () => {},
      } = action.payload;

      const response = yield call(api.syncAccountingIntegrationData, { organizationId, platform });

      yield put(accountingIntegrationActions.syncAccountingIntegrationData.queued(response.data));

      let { task } = response.data;

      while (!["rejected", "completed", "failed"].includes(task.status)) {
        yield delay(3000);

        yield put(
          accountingIntegrationActions.syncAccountingIntegrationStatus.request({
            organizationId,
            platform,
            taskId: task.id,
          }),
        );

        const statusAction = yield take([
          accountingIntegrationActions.syncAccountingIntegrationStatus.success,
          accountingIntegrationActions.syncAccountingIntegrationStatus.error,
        ]);

        if (
          statusAction.type ===
          accountingIntegrationActions.syncAccountingIntegrationStatus.error.toString()
        ) {
          throw new Error("Error fetching task status");
        }

        task = statusAction.payload.task;
      }

      yield put(accountingIntegrationActions.syncAccountingIntegrationData.success(response.data));

      if (task.status === "completed") {
        onCompletion({ task });
      } else {
        onFailed({ task });
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.syncAccountingIntegrationData.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
  *syncAccountingIntegrationStatus(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        platform,
        taskId,
      } = action.payload;

      const response = yield call(api.getProvisionAccountingIntegrationStatus, {
        organizationId,
        platform,
        taskId,
      });

      yield put(
        accountingIntegrationActions.syncAccountingIntegrationStatus.success(response.data),
      );
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.syncAccountingIntegrationStatus.error(error));
    }
  },
  *validatePublishDocuments(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentIds,
        platform,
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.validatePublishDocumentsToAccounting, {
        organizationId,
        documentIds,
        platform,
      });

      yield put(accountingIntegrationActions.validatePublishDocuments.success(response.data));

      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.validatePublishDocuments.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
  *publishDocuments(action) {
    try {
      const {
        organizationId = yield useCurrentOrganizationId(),
        documentIds,
        platform,
        paymentMethodAccountId,
        updateAlreadyPublished = false,
        publishWithWarnings = false,
        onTaskQueued = () => {},
        onCompletion = () => {},
      } = action.payload;

      const response = yield call(api.publishDocumentsToAccounting, {
        organizationId,
        documentIds,
        platform,
        paymentMethodAccountId,
        updateAlreadyPublished,
        publishWithWarnings,
      });

      onTaskQueued(response.data);

      let { tasks } = response.data;
      const taskIds = tasks.map((task) => task.id);

      if (tasks.length > 0) {
        yield put(
          documentActions.getList.request({ documentIds: tasks.map((task) => task.documentId) }),
        );
      }

      while (tasks.some((task) => !["completed", "failed"].includes(task.status))) {
        yield delay(3000);

        yield put(
          accountingIntegrationActions.publishDocumentStatus.request({
            organizationId,
            taskIds,
          }),
        );
        const statusAction = yield take([
          accountingIntegrationActions.publishDocumentStatus.success,
          accountingIntegrationActions.publishDocumentStatus.error,
        ]);
        yield put(
          documentActions.getList.request({
            documentIds: statusAction.payload.tasks.map((task) => task.documentId),
          }),
        );

        yield put(
          itemCategoryActions.list.request({
            organizationId,
          }),
        );

        if (
          statusAction.type === accountingIntegrationActions.publishDocumentStatus.error.toString()
        ) {
          throw new Error("Error fetching task status");
        }

        tasks = statusAction.payload.tasks;
      }

      yield put(accountingIntegrationActions.publishDocuments.success(response.data));

      // Reload the affected documents
      if (tasks.length > 0) {
        yield put(
          documentActions.getList.request({ documentIds: tasks.map((task) => task.documentId) }),
        );
      }

      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.publishDocuments.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
  *publishDocumentsStatus(action) {
    try {
      const { organizationId, taskIds, onCompletion = () => {} } = action.payload;

      const response = yield call(api.getPublishDocumentsStatus, { organizationId, taskIds });

      yield put(accountingIntegrationActions.publishDocumentStatus.success(response.data));

      onCompletion(response.data);
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(accountingIntegrationActions.publishDocumentStatus.error(error));

      if (action.payload.onError) {
        action.payload.onError(error);
      }
    }
  },
};
