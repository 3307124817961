import { call, put, select, take } from "redux-saga/effects";
import i18next from "i18next";
import moment from "moment";
import { get } from "lodash";
import { LocalesApi } from "./api";
import { localesActions } from "./actions";
import { debugPrint, debugPrintAndLogError } from "../logging/logger";
import { userActions } from "../users/actions";
import { getCurrentLanguage, rememberMomentLocale } from "../languageService";

export const localeSagas = {
  *list() {
    try {
      const response = yield call(LocalesApi.list);
      yield put(localesActions.list.success(response.data));
    } catch (error) {
      yield put(localesActions.list.error(error));
      debugPrintAndLogError(error);
    }
  },
};

export function* autoDetectUserLocalePropertiesIfNeeded(user) {
  try {
    const navigatorLanguage = navigator.language;
    const { language, region } = new Intl.Locale(navigatorLanguage);
    const updateProps = {};

    if (language && !user.webLanguage) {
      updateProps.webLanguage = language.toLowerCase();
    }
    if (region && !user.webLocale) {
      let supportedLocales = yield select((state) => get(state, "locales.collections.all", []));

      if (supportedLocales.length === 0) {
        yield put(localesActions.list.request());
        yield take([localesActions.list.success, localesActions.list.error]);
        supportedLocales = yield select((state) => get(state, "locales.collections.all", []));
      }

      const regionEntry = supportedLocales.find(
        (r) => r.code.toLowerCase() === region.toLowerCase(),
      );

      if (regionEntry) {
        updateProps.webLocale = regionEntry.code || null;
      }
    }

    if (Object.keys(updateProps).length > 0) {
      debugPrint(`Update user with: ${JSON.stringify(updateProps)}`);
      yield put(userActions.updateMe.request(updateProps));
      yield take([userActions.updateMe.success, userActions.updateMe.error]);
      const newUser = yield select((state) => state.login.collections.loginInfo.user);
      setupLanguageAndRegionIfNeeded(newUser);
    }
  } catch (error) {
    debugPrintAndLogError(error);
  }
}

export function setupLanguageAndRegionIfNeeded(user) {
  setupLanguageIfNeeded(user);
  setupLocaleIfNeeded(user);
}

function setupLanguageIfNeeded(user) {
  const currentLanguage = getCurrentLanguage().toLowerCase();
  const userLanguage = user.webLanguage ? user.webLanguage.toLowerCase() : "";

  if (userLanguage && currentLanguage !== userLanguage) {
    debugPrint(`Change language to: ${user.webLanguage}`);
    i18next.changeLanguage(user.webLanguage);
  }
}

function setupLocaleIfNeeded(user) {
  if (!user.webLocale) return;

  const userLocale = user.webLocale.toLowerCase();

  if (["en", "en-us"].includes(userLocale)) {
    // This is the default and does not need to be loaded.
    setLocaleForMoment("en");
    return;
  }

  setMomentLocaleAndImportIfNeeded(userLocale);
}

export function setMomentLocaleAndImportIfNeeded(locale) {
  import(`moment/locale/${locale}`)
    .then(() => {
      setLocaleForMoment(locale);
    })
    .catch((error) => {
      handleMomentLocaleError(error);
    });
}

function setLocaleForMoment(locale) {
  moment.locale(locale);
  debugPrint(`Set moment locale to '${moment().locale()}'`);
  rememberMomentLocale(locale);
}

function handleMomentLocaleError(error) {
  moment.locale(getCurrentLanguage());
  debugPrint(error);
  debugPrint("Set moment locale fallback");
}
