import { fromSnakeToCamel } from "../../utils/letterCasingUtils";
import * as backendService from "../backendService";

export const LocalesApi = {
  async list() {
    const url = "/locales";

    const response = await backendService.get({ url });

    return { data: fromSnakeToCamel(response.data) };
  },
};
