import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { userActions } from "./actions";
import { organizationActions } from "../organization/actions";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { setupLanguageAndRegionIfNeeded } from "../locales/sagas";
import { debugPrint, debugPrintAndLogError } from "../logging/logger";
import { session } from "../login/sagas";

export const userSagas = {
  *updateMe(action) {
    try {
      const {
        name,
        preferredCurrency,
        preferredTaxMode,
        webLocale,
        webLanguage,
        preferredDateFormat,
        preferredDateFormats,
        marketingConsent,
      } = action.payload;

      const response = yield call(api.updateMe, {
        name,
        preferredCurrency,
        preferredTaxMode,
        webLocale,
        webLanguage,
        preferredDateFormat,
        preferredDateFormats,
        marketingConsent,
      });

      logAnalyticsEvent("user_edited");

      yield put(organizationActions.users.list.request());
      yield put(userActions.updateMe.success(response.data));

      if (webLocale) {
        debugPrint("Reload language and locale.");
        setupLanguageAndRegionIfNeeded(response.data.user);
      }
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(userActions.updateMe.error({ error }));
    }
  },
  *submitOnboarding(action) {
    try {
      const {
        organizationId,
        organizationName,
        userName,
        preferredCurrency,
        preferredTaxMode,
        marketingConsent,
        useCases,
        businessSize,
        heardAboutUs,
        heardAboutUsDetail,
      } = action.payload;

      const response = yield call(api.submitOnboarding, {
        organizationId,
        organizationName,
        userName,
        preferredCurrency,
        preferredTaxMode,
        marketingConsent,
        useCases,
        businessSize,
        heardAboutUs,
        heardAboutUsDetail,
      });

      logAnalyticsEvent("onboarding_submitted");

      yield call(session.loginInfo);
      yield put(userActions.submitOnboarding.success(response.data));
    } catch (error) {
      debugPrintAndLogError(error);
      yield put(userActions.submitOnboarding.error({ error }));
    }
  },
};
