import { call, put } from "redux-saga/effects";
import * as api from "./api";
import { organizationActions } from "./actions";
import { useCurrentOrganizationId } from "../../utils/sagaUtils";
import { logAnalyticsEvent } from "../analytics/analyticsService";
import { debugPrintAndLogError } from "../logging/logger";
import { session } from "../login/actions";

export const organizationSagas = {
  *getCurrentSubscription(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId() } = action.payload;
      const response = yield call(api.getCurrentSubscription, {
        organizationId,
      });

      yield put(organizationActions.getCurrentSubscription.success(response.data));
    } catch (error) {
      yield put(organizationActions.getCurrentSubscription.error(error));
      debugPrintAndLogError(error);
    }
  },
  *create(action) {
    try {
      const { name, onCompletion = () => {} } = action.payload;

      const response = yield call(api.createOrganization, { name });

      logAnalyticsEvent("organization_create");

      yield put(organizationActions.create.success(response.data));
      onCompletion(response.data);
    } catch (error) {
      yield put(organizationActions.create.error(error));
      debugPrintAndLogError(error);
    }
  },
  *update(action) {
    try {
      const { organizationId = yield useCurrentOrganizationId(), name } = action.payload;

      const response = yield call(api.editOrganization, {
        organizationId,
        name,
      });

      logAnalyticsEvent("organization_edit");

      yield put(session.loginInfo.refresh());

      yield put(organizationActions.update.success(response.data));
    } catch (error) {
      yield put(
        organizationActions.update.error({
          error,
        }),
      );
      debugPrintAndLogError(error);
    }
  },
  users: {
    *list(action) {
      try {
        const { payload = {} } = action;
        const { organizationId = yield useCurrentOrganizationId() } = payload;
        const response = yield call(api.getOrganizationUsers, { organizationId });
        yield put(organizationActions.users.list.success(response.data));
      } catch (error) {
        yield put(organizationActions.users.list.error({ error }));
        debugPrintAndLogError(error);
      }
    },
  },
};
