import { createAction } from "redux-actions";

const namespaces = {
  locales: "LOCALES",
};

export const localesActions = {
  list: {
    request: createAction(`${namespaces.locales}/LIST/REQUEST`),
    success: createAction(`${namespaces.locales}/LIST/SUCCESS`),
    error: createAction(`${namespaces.locales}/LIST/ERROR`),
  },
};
