import { all, takeEvery, takeLatest } from "redux-saga/effects";

import * as loginActions from "./services/login/actions";
import * as dashboardActions from "./services/dashboard/actions";
import { documentActions } from "./services/documents/actions";

import * as loginSagas from "./services/login/sagas";
import * as dashboardSagas from "./services/dashboard/sagas";
import { userSagas } from "./services/users/sagas";
import { organizationSagas } from "./services/organization/sagas";
import { documentCategorySagas } from "./services/document-categories/sagas";
import { documentSagas } from "./services/documents/sagas";
import { documentCategoryActions } from "./services/document-categories/actions";
import { organizationActions } from "./services/organization/actions";
import { userActions } from "./services/users/actions";
import { currencyActions } from "./services/currency/actions";
import { currencySagas } from "./services/currency/sagas";
import { itemCategoryActions } from "./services/item-categories/actions";
import { itemCategorySagas } from "./services/item-categories/sagas";
import { taxRateActions } from "./services/tax-rates/actions";
import { taxRateSagas } from "./services/tax-rates/sagas";
import { subscriptionActions } from "./services/subscriptions/actions";
import { subscriptionSagas } from "./services/subscriptions/sagas";
import { documentExportSagas } from "./services/document-exports/sagas";
import { documentExportActions } from "./services/document-exports/actions";
import { localeSagas } from "./services/locales/sagas";
import { localesActions } from "./services/locales/actions";
import { appConfigurationActions } from "./services/app-configurations/actions";
import { appConfigurationSagas } from "./services/app-configurations/sagas";
import { hashTagActions } from "./services/hash-tags/actions";
import { hashTagsSagas } from "./services/hash-tags/sagas";
import { paymentMethodActions } from "./services/payment-methods/actions";
import { paymentMethodSagas } from "./services/payment-methods/sagas";
import { organizationWebhookActions } from "./services/organization-webhooks/actions";
import { organizationWebhookSagas } from "./services/organization-webhooks/sagas";
import { transactionLineCollectionSagas } from "./services/transaction-line-collections/sagas";
import { bankStatementSagas } from "./services/bank-statements/sagas";
import { transactionLineCollectionActions } from "./services/transaction-line-collections/actions";
import { bankStatementActions } from "./services/bank-statements/actions";
import { accountableActions } from "./services/accountables/actions";
import { accountableSagas } from "./services/accountables/sagas";
import { accountingIntegrationActions } from "./services/integrations/accounting/actions";
import { accountingIntegrationSagas } from "./services/integrations/accounting/sagas";
import { paymentMethodAccountActions } from "./services/payment-method-accounts/actions";
import { paymentMethodAccountSagas } from "./services/payment-method-accounts/sagas";
import { documentMassUploadActions } from "./services/document-mass-uploads/actions";
import { documentMassUploadSagas } from "./services/document-mass-uploads/sagas";

export function* sagas() {
  yield all([
    takeLatest(appConfigurationActions.get.request, appConfigurationSagas.get),
    takeLatest(loginActions.session.login.request, loginSagas.session.login),
    takeLatest(loginActions.session.login.oauth.request, loginSagas.session.loginOAuth),
    takeLatest(loginActions.session.loginInfo.request, loginSagas.session.loginInfo),
    takeLatest(loginActions.session.loginInfo.refresh, loginSagas.session.loginInfo),
    takeLatest(loginActions.session.logout.request, loginSagas.session.logout),
    takeLatest(loginActions.session.redirect.login, loginSagas.session.expired),
    takeLatest(
      loginActions.session.organization.pick.request,
      loginSagas.session.organization.pick,
    ),

    takeLatest(currencyActions.list.request, currencySagas.list),
    takeEvery(currencyActions.convert.request, currencySagas.convert),

    takeLatest(localesActions.list.request, localeSagas.list),

    takeEvery(userActions.updateMe.request, userSagas.updateMe),
    takeLatest(userActions.submitOnboarding.request, userSagas.submitOnboarding),

    takeLatest(dashboardActions.dashboard.init.request, dashboardSagas.dashboard.init),

    takeLatest(documentCategoryActions.list.request, documentCategorySagas.list),

    takeLatest(itemCategoryActions.list.request, itemCategorySagas.list),
    takeEvery(itemCategoryActions.create.request, itemCategorySagas.create),
    takeEvery(itemCategoryActions.update.request, itemCategorySagas.update),
    takeEvery(itemCategoryActions.destroy.request, itemCategorySagas.destroy),

    takeLatest(accountableActions.list.request, accountableSagas.list),
    takeEvery(accountableActions.create.request, accountableSagas.create),
    takeEvery(accountableActions.update.request, accountableSagas.update),
    takeEvery(accountableActions.destroy.request, accountableSagas.destroy),

    takeLatest(hashTagActions.list.request, hashTagsSagas.list),
    takeEvery(hashTagActions.update.request, hashTagsSagas.update),
    takeEvery(hashTagActions.destroy.request, hashTagsSagas.destroy),

    takeLatest(paymentMethodActions.list.request, paymentMethodSagas.list),
    takeEvery(paymentMethodActions.create.request, paymentMethodSagas.create),
    takeEvery(paymentMethodActions.update.request, paymentMethodSagas.update),
    takeEvery(paymentMethodActions.destroy.request, paymentMethodSagas.destroy),

    takeLatest(paymentMethodAccountActions.list.request, paymentMethodAccountSagas.list),

    takeEvery(organizationWebhookActions.list.request, organizationWebhookSagas.list),
    takeEvery(organizationWebhookActions.create.request, organizationWebhookSagas.create),
    takeEvery(organizationWebhookActions.update.request, organizationWebhookSagas.update),
    takeEvery(organizationWebhookActions.destroy.request, organizationWebhookSagas.destroy),

    takeLatest(taxRateActions.list.request, taxRateSagas.list),
    takeEvery(taxRateActions.create.request, taxRateSagas.create),
    takeEvery(taxRateActions.update.request, taxRateSagas.update),
    takeEvery(taxRateActions.destroy.request, taxRateSagas.destroy),

    takeLatest(taxRateActions.listAccountingTaxCodes.request, taxRateSagas.listAccountingTaxCodes),

    takeLatest(transactionLineCollectionActions.list.request, transactionLineCollectionSagas.list),
    takeLatest(
      transactionLineCollectionActions.show.byId.request,
      transactionLineCollectionSagas.find,
    ),
    takeLatest(
      transactionLineCollectionActions.show.byDocumentId.request,
      transactionLineCollectionSagas.findByDocumentId,
    ),
    takeEvery(
      transactionLineCollectionActions.destroy.request,
      transactionLineCollectionSagas.destroy,
    ),
    takeEvery(
      transactionLineCollectionActions.transactionLine.update.request,
      transactionLineCollectionSagas.transactionLine.update,
    ),
    takeEvery(
      transactionLineCollectionActions.transactionLine.updateMultiple.request,
      transactionLineCollectionSagas.transactionLine.updateMultiple,
    ),
    takeEvery(
      transactionLineCollectionActions.transactionLine.destroy.request,
      transactionLineCollectionSagas.transactionLine.destroy,
    ),
    takeEvery(
      transactionLineCollectionActions.transactionLine.convert.request,
      transactionLineCollectionSagas.transactionLine.convert,
    ),
    takeEvery(
      transactionLineCollectionActions.transactionLine.convertMultiple.request,
      transactionLineCollectionSagas.transactionLine.convertMultiple,
    ),
    takeEvery(
      bankStatementActions.extract.getTask.byDocumentId.request,
      bankStatementSagas.getTaskByDocumentId,
    ),
    takeLatest(bankStatementActions.extract.quota.request, bankStatementSagas.getExtractQuota),
    takeLatest(bankStatementActions.extract.list.request, bankStatementSagas.listTasks),
    takeLatest(bankStatementActions.extract.begin.request, bankStatementSagas.extractBegin),
    takeEvery(bankStatementActions.extract.status.request, bankStatementSagas.extractStatus),
    takeEvery(
      bankStatementActions.extract.status.poll.request,
      bankStatementSagas.extractStatusPoll,
    ),

    takeLatest(organizationActions.create.request, organizationSagas.create),
    takeLatest(organizationActions.update.request, organizationSagas.update),
    takeLatest(organizationActions.users.list.request, organizationSagas.users.list),

    takeLatest(
      organizationActions.getCurrentSubscription.request,
      organizationSagas.getCurrentSubscription,
    ),

    takeLatest(
      subscriptionActions.redeemProductCoupon.request,
      subscriptionSagas.redeemProductCoupon,
    ),

    takeEvery(documentActions.list.request, documentSagas.list),
    takeLatest(documentActions.search.request, documentSagas.search),
    takeEvery(documentActions.count.request, documentSagas.count),
    takeEvery(documentActions.totalValue.request, documentSagas.totalValue),
    takeLatest(documentActions.listMeta.request, documentSagas.listMeta),
    takeEvery(documentActions.get.request, documentSagas.get),
    takeEvery(documentActions.getList.request, documentSagas.getList),
    takeEvery(documentActions.create.request, documentSagas.create),
    takeEvery(documentActions.update.request, documentSagas.update),
    takeEvery(documentActions.updateMultiple.request, documentSagas.updateMultiple),
    takeEvery(documentActions.moveToOrganization.request, documentSagas.moveToOrganization),
    takeEvery(documentActions.upload.request, documentSagas.upload),
    takeEvery(documentActions.replaceFile.request, documentSagas.replaceFile),
    takeEvery(documentActions.uploadProcessedFile.request, documentSagas.uploadProcessedFile),
    takeEvery(documentActions.trash.request, documentSagas.trash),
    takeEvery(documentActions.restore.request, documentSagas.restore),
    takeEvery(documentActions.destroy.request, documentSagas.destroy),
    takeEvery(documentActions.destroyList.request, documentSagas.destroyList),
    takeEvery(documentActions.deleteFile.request, documentSagas.deleteFile),
    takeEvery(documentActions.ocrAnalysis.begin.request, documentSagas.ocrAnalysis.begin),
    takeEvery(documentActions.ocrAnalysis.status.request, documentSagas.ocrAnalysis.status),

    // Document exports
    takeEvery(documentExportActions.list.request, documentExportSagas.list),
    takeEvery(documentExportActions.create.request, documentExportSagas.create),
    takeEvery(documentExportActions.status.request, documentExportSagas.status),
    takeEvery(documentExportActions.destroy.request, documentExportSagas.destroy),

    // Document mass uploads
    takeLatest(documentMassUploadActions.list.request, documentMassUploadSagas.list),
    takeEvery(documentMassUploadActions.create.request, documentMassUploadSagas.create),
    takeEvery(documentMassUploadActions.uploadFile.request, documentMassUploadSagas.uploadFile),
    takeEvery(documentMassUploadActions.beginTask.request, documentMassUploadSagas.beginTask),
    takeEvery(documentMassUploadActions.get.request, documentMassUploadSagas.getTask),
    takeEvery(documentMassUploadActions.monitorTask.request, documentMassUploadSagas.monitorTask),
    takeEvery(documentMassUploadActions.destroy.request, documentMassUploadSagas.destroy),
    takeLatest(
      documentMassUploadActions.prepareMassUploadTask.request,
      documentMassUploadSagas.prepareMassUploadTask,
    ),

    // Accounting integrations
    takeEvery(
      accountingIntegrationActions.updateSettings.request,
      accountingIntegrationSagas.updateSettings,
    ),
    takeEvery(
      accountingIntegrationActions.validatePublishDocuments.request,
      accountingIntegrationSagas.validatePublishDocuments,
    ),
    takeEvery(
      accountingIntegrationActions.publishDocuments.request,
      accountingIntegrationSagas.publishDocuments,
    ),
    takeEvery(
      accountingIntegrationActions.publishDocumentStatus.request,
      accountingIntegrationSagas.publishDocumentsStatus,
    ),
    takeLatest(
      accountingIntegrationActions.syncAccountingIntegrationData.request,
      accountingIntegrationSagas.syncAccountingIntegrationData,
    ),
    takeLatest(
      accountingIntegrationActions.syncAccountingIntegrationStatus.request,
      accountingIntegrationSagas.syncAccountingIntegrationStatus,
    ),
  ]);
}

export default sagas;
